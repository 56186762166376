.login-container {
  height: 100vh;
  width: 100%;
  z-index: 1000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.login-container > .login-content {
  width: 300px;
  margin: 5rem auto auto;
}

/*# sourceMappingURL=index.4ad8845c.css.map */
