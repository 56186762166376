.login-container{
  position: fixed;
  height: 100vh;
  width:100%;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: 1000;
}

.login-container >.login-content{
  width:300px;
  margin:auto;
  margin-top:5rem;
}